import { createSlice } from '@reduxjs/toolkit';

const mapSlice = createSlice({
  name: 'map',
  initialState: { contacts: null},
  reducers: {
    setContacts: (state, action) => {
      state.contacts = action.payload;
    },
    clearContacts: (state) => {
      state.contacts = null;
    },
  },
});

export const { setContacts, clearContacts } = mapSlice.actions;
export const currentContacts = (state) => state.map.contacts;
export default mapSlice.reducer;
