import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import toastSlice from './slices/toastSlice';
import polygonSlice from './slices/polygonCoordinates';
import taskSlice from './slices/taskSlice';
import dashboardSlice from './slices/dashboardSlice';
import mapSlice from './slices/mapSlice';

const config = () => {
  return {
    isProd: ['cg-web.stage.ceegees-inc.com'].includes(window.location.hostname)
  }
}
const store = configureStore({
  reducer: {
    config,
    user: userReducer,
    toast: toastSlice,
    polygons: polygonSlice,
    task: taskSlice,
    dashboard: dashboardSlice,
    map: mapSlice
  },
});
window.gAppStore = store;
console.log(store.getState());

export default store;
