import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import {
  Box,
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Tooltip,
  Avatar,
  Menu,
  MenuItem,
  Paper
} from '@mui/material';
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon
} from '@mui/icons-material';
import {
  Dashboard as DashboardIcon,
  AccountBox as Profile,
  CorporateFareRounded as Organisation,
  ExitToAppRounded as ExitToAppIcon,
  ImportContactsRounded as ContactsRoundedIcon,
  Groups2 as Groups2Icon,
  Campaign as CampaignIcon,
  Segment as SegmentIcon,
  Poll as PollIcon,
  ReceiptLong as ReceiptLongIcon,
  PendingActions as PendingActionsIcon,
  Settings as SettingsIcon,
  Task as TaskIcon,
  Diversity3Rounded,
  Email as EmailIcon,
  Workspaces as WorkspacesIcon,
  List as ListIcon,
  LabelImportant as LabelImportantIcon,
  RestorePage as RestorePageIcon
} from '@mui/icons-material';
import TextsmsIcon from '@mui/icons-material/Textsms';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser, setCurrentOrganizaion } from './redux/slices/userSlice';
import { useMatch, useNavigate } from 'react-router-dom';
import createAxiosInstance from './utils/axiosInstance';
import UnauthorizedAccessPage from './UnautherisedUI';
import { getFullName } from './utilsn';
import CgIcon from "./images/cg-logo.png";
import Diversity2Icon from '@mui/icons-material/Diversity2';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import IosShareIcon from '@mui/icons-material/IosShare';
import GroupsIcon from '@mui/icons-material/Groups';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TagIcon from '@mui/icons-material/Tag';
import SearchContacts from './contacts/ContactSearchComp';

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});


const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const CustomDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const DashboardLayout = ({ children }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [menuItems, setMenuItems] = useState([]);
  const [orgMenu, setOrgMenu] = useState([]);
  const [useDashboardLayout, setUseDashboardLayout] = useState(false);
  const [selectedOrgData, setSelectedOrgData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const router = '';
  const currentPath = router;
  const org = 'test';
  const publicPaths = ['/login', '/accept-invite'];
  const isPublicPath = publicPaths?.includes(currentPath);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [organisationsList, setOrganisationsList] = useState([]);

  const [mobileOpen, setMobileOpen] = useState(true);
  const [isClosing, setIsClosing] = useState(false);

  //  const [openOrgMenu, setOpenOrgMenu] = useState(false);
  let orgId = '';
  const openMenu = Boolean(anchorEl);

  const match = useMatch('/org/:orgId/*')
  if (match) {
    orgId = match?.params?.orgId;
  };
  const authUser = useSelector((state) => state?.user?.user);
  const firstName = authUser?.first_name;
  const lastName = authUser?.last_name;
  const userName = authUser?.username;
  const acceptedOrgId = useSelector((state) => state?.user?.acceptedOrgId);

  const orgInfo =  useSelector((state) => state?.user?.organization);
  const isOrgMember = selectedOrgData?.length && selectedOrgData[0]?.attributes?.team_roles?.data.find(item => ((item?.attributes?.email === authUser?.email) && (item?.attributes?.status === 'active')));
  dispatch(setCurrentOrganizaion(isOrgMember));

  useEffect(() => {
    fetchOrganisationsList();
  }, []);

  useEffect(() => {
    fetchOrgDetailById();
  }, [orgId]);

  const fetchOrganisationsList = async () => {
    try {
      const encodedEmail = encodeURIComponent(authUser?.email);
      const resp = await createAxiosInstance().get(`api/app-organisations?sort[0]=createdAt:desc&filters[team_roles][email][$eq]=${encodedEmail}&filters[team_roles][status][$eq]=active`);
      setOrganisationsList(resp?.data);
      if (orgId || isOrgMember) { return; }
      if (acceptedOrgId) {
        return navigate(`/org/${acceptedOrgId}/members?tab=active`);
      }
      // navigate(`/org/${resp?.data?.data[0]?.id}/members?tab=active`);
      return navigate(`/org/${resp?.data?.data[0]?.id}/contacts`); // Navigating to contacts instead of members.
    } catch (error) {
      console.log("error in fetching orgs::::", error)
    }

  };

  const fetchOrgDetailById = async () => {
    if (!orgId) return;
    const resp = await createAxiosInstance().get(`api/app-organisations?filters[id]=${orgId}&populate[team_roles][populate][0]=role&populate[team_roles][populate][1]=default_contact`, { params: { orgId } });
    setSelectedOrgData(resp?.data?.data);
  }

  useEffect(() => {
    const menuItemsArray = [
      { text: 'Organisations', icon: <Groups2Icon />, route: `/org?tab=my-org` },
    ];
    setMenuItems(menuItemsArray);
    const orgMenu = [
      {
        name: 'Dashboard',
        type: 'section',
        roles: ['admin', 'team-manager', 'volunteer'],
        subMenus: [
          { text: 'Dashboard', icon: <DashboardIcon />, route: `/org/${orgId}/dashboard`, key: 'dashboard',roles:['admin','volunteer','team-manager'] },
          { text: 'Notifications', icon: <NotificationsIcon />, route: `/org/${orgId}/notifications`, key: 'notifications',roles:['admin','volunteer','team-manager'] }
        ]
      },
      {
        name: 'Contact',
        type: 'section',
        roles: ['admin', 'team-manager', 'volunteer'],
        subMenus: [
          { text: 'All Contacts', icon: <ContactsRoundedIcon />, route: `/org/${orgId}/contacts`, key: 'contacts',roles:['admin','volunteer','team-manager'] },
          { text: 'Advanced Search', icon: <ManageSearchIcon />, route: `/org/${orgId}/segments?tab=search`, key: 'segments',roles:['admin','team-manager'] },
          { text: 'Imports', icon: <SaveAltIcon />, route: `/org/${orgId}/import-history`, key: 'import-history',roles:['admin'] },
          { text: 'Exports', icon: <IosShareIcon />, route: `/org/${orgId}/export-history`, key: 'export-history',roles:['admin'] },
        ]
      },
      {
        name: 'Assigned to Me',
        type: 'section',
        roles: ['admin', 'team-manager', 'volunteer'],
        subMenus: [
          { text: 'My Tasks', icon: <TaskIcon />, route: `/org/${orgId}/my-tasks`, key: 'my-tasks',roles:['admin','volunteer','team-manager'] },
          { text: 'My People', icon: <Diversity3Rounded />, route: `/org/${orgId}/my-people`, key: 'my-people',roles:['admin','team-manager'] },
          { text: 'Assigned Lists', icon: <ListAltIcon />, route: `/org/${orgId}/assigned-lists`, key: 'assigned-lists',roles:['admin','team-manager'] },
          { text: 'My Connections', icon: <Diversity2Icon />, route: `/org/${orgId}/my-connections`, key: 'my-connections',roles:['admin','team-manager'] },
        ]
      },
      {
        name: 'Volunteer Management',
        roles: ['admin', 'team-manager'],
        type: 'section',
        subMenus: [
          { text: 'Tasks', icon: <TaskIcon />, route: `/org/${orgId}/admin-tasks`, key: 'admin-tasks',roles:['admin','team-manager'] },
          { text: 'Surveys', icon: <PollIcon />, route: `/org/${orgId}/surveys`, key: 'surveys',roles:['admin','team-manager'] },
          { text: 'Members', icon: <GroupsIcon />, route: `/org/${orgId}/members?tab=active`, key: 'members',roles:['admin','team-manager'] },
        ]
      },
      {
        name: 'Communications',
        type: 'section',
        roles: ['admin'],
        subMenus: [
          { text: 'Email', icon: <EmailIcon />, route: `/org/${orgId}/email-communications?tab=blasts`, key: 'email-communications',roles:['admin','team-manager'] },
          { text: 'SMS', icon: <TextsmsIcon />, route: `/org/${orgId}/sms-templates`, key: 'sms-templates',roles:['admin','team-manager'] },
        ]
      },
      {
        name: 'Settings & Configuration',
        type: 'section',
        roles: ['admin', 'team-manager', 'volunteer'],
        subMenus: [
          { text: 'Tag Sections', icon: <TagIcon />, route: `/org/${orgId}/sections`, key: 'sections',roles:['admin'] },
          { text: 'Account Settings', icon: <SettingsIcon />, route: `/org/${orgId}/settings`, key: 'settings',roles:['admin','volunteer','team-manager'] },
        ]
      },

      // { text: 'DashBoard', icon: <DashboardIcon />, route: `/org/${orgId}/dashboard`, key: 'dashboard' },
      // { text: 'Contacts', icon: <ContactsRoundedIcon />, route: `/org/${orgId}/contacts`, key: 'contacts' },
      // { text: 'Saved Searches/Lists', icon: <SegmentIcon />, route: `/org/${orgId}/segments?tab=search`, key: 'segments' },
      // { text: 'Notifications', icon: <NotificationsIcon />, route: `/org/${orgId}/notifications`, key: 'notifications' },
      // { text: 'My People', icon: <Diversity3Rounded />, route: `/org/${orgId}/my-people`, key: 'my-people' },
      // { text: 'Assigned Lists', icon: <ListIcon />, route: `/org/${orgId}/assigned-lists`, key: 'assigned-lists' },
      // { text: 'Surveys', icon: <PollIcon />, route: `/org/${orgId}/surveys`, key: 'surveys' },
      // { text: 'Tasks', icon: <TaskIcon />, route: `/org/${orgId}/tasks`, key: 'tasks' },

      // // { text: 'Pending Requests', icon: <PendingActionsIcon />, route: `/org/${orgId}/pending-requests`, key: 'pending-requests' },
      // { text: 'Members', icon: <Diversity3Rounded />, route: `/org/${orgId}/members?tab=active`, key: 'members' },
      // { text: 'Teams', icon: <WorkspacesIcon />, route: `/org/${orgId}/teams?tab=myteams`, key: 'teams' },
      // { text: 'Email Templates', icon: <EmailIcon />, route: `/org/${orgId}/email-templates`, key: 'email-templates' },
      // { text: 'Settings', icon: <SettingsIcon />, route: `/org/${orgId}/settings`, key: 'settings' },
      // //  { text: 'Campaigns', icon: <CampaignIcon />, route: `/` },
      // //  { text: 'Surveys', icon: <PollIcon />, route: `/` },,
      // //  { text: 'Audit Log', icon: <ReceiptLongIcon />, route: `/` }
    ];
    setOrgMenu(orgMenu);
  }, [orgId]);

  useEffect(() => {
    if (mobileOpen) {
      setOpen(true);
    }
  }, [mobileOpen]);

  const handleDrawerOpen = () => {
    setOpen(!open);
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isSelected = (key) => {
    // const pathSegments = window?.location?.pathname.split("/");
    // const lastSegment = pathSegments[pathSegments.length - 1];
    // const isSelected = lastSegment === key;
    // return isSelected;
    return window?.location?.pathname?.includes(key);
  }

  const handleLogout = () => {
    localStorage.removeItem('token');
    dispatch(clearUser());
    window.location.href = '/login';
    //  router.push('/sign-in');
    //  dispatch(clearCurrentOrganizaion());
    //  dispatch(clearCurrentRole());
  };
  const userRole= orgInfo?.attributes?.role?.data?.attributes?.name;
  const filteredMenu = orgMenu
  .filter((menuSection) => menuSection?.roles?.includes(userRole)) // Filter sections
  .map((menuSection) => {
    const filteredSubMenus = menuSection.subMenus.filter((subMenu) =>
      subMenu?.roles?.includes(userRole)
    );

    return filteredSubMenus.length > 0
      ? { ...menuSection, subMenus: filteredSubMenus }
      : null;
  })
  .filter(Boolean);

  const drawer = (
    <>
      <DrawerHeader sx={{ borderRight: 'none', display: { xs: 'block' } }}>
      </DrawerHeader>

      <List>
        <Collapse in={orgId} timeout="auto" unmountOnExit>
          <List disablePadding sx={{ marginBottom: '50px' }}>
            {
              filteredMenu.map((menuSection, idx) => {
                let menuData = [];
                if (open && menuSection?.type == 'section') {
                  menuData.push(<div className='cg-menu-label'>{menuSection?.name}</div>)
                }

                menuSection?.subMenus.map(({ text, icon, route, key }) => {
                  menuData.push(
                    <ListItemButton onClick={() => navigate(route)}
                      sx={{
                        margin: open ? '0px 15px' : '0px 10px',
                        mt: 0.5,
                        borderRadius: '8px',
                        padding: !open ? '10px' : 'inheirt',
                        '&:hover': {
                          color: theme.palette.secondary.main,
                          backgroundColor: 'rgb(237, 231, 246)',
                          '.MuiListItemIcon-root': {
                            color: theme.palette.secondary.main
                          }
                        },
                        '&.Mui-selected': {
                          color: theme.palette.secondary.main,
                          backgroundColor: 'rgb(237, 231, 246)',
                          '.MuiListItemIcon-root': {
                            color: theme.palette.secondary.main
                          },
                          '&:hover': {
                            backgroundColor: 'rgb(237, 231, 246)',
                          },
                        },
                      }}
                      selected={isSelected(key)}>
                      <ListItemIcon color={theme.palette.secondary.main}>
                        {icon}
                      </ListItemIcon>
                      {open && <ListItemText primary={text} className="ellipsis" sx={{ textWrap: 'initial' }} />}
                    </ListItemButton>
                  )
                })

                if (open && idx != orgMenu?.length - 1) {
                  menuData.push(<Divider className='cg-menu-divider' variant="middle" component="li" />)
                }
                return menuData;
              })
            }
          </List>
        </Collapse>
      </List>
    </>
  )

  return <Box sx={{ display: 'flex', height: '100vh' }}>
    <CssBaseline />
    <AppBar position="fixed" elevation={0} open={open}>
      <Toolbar className='cg-toolbar-top'>
        <Avatar
          src={CgIcon}
          sx={{
            width: 30,
            height: 30,
            background: "#ddd",
            fontSize: "2rem",
            fontWeight: "400",
            textTransform: "capitalize",
            display: {
              xs: 'none',
              sm: 'block'
            }
          }}
        /> 
        <Typography variant="h3" noWrap component="div" sx={{marginRight: '30px', marginLeft: "10px", display: { xs: 'none', sm: 'block'}}}>
          Campaign Guru
          <div style={{ position: 'absolute', maxWidth: '140px', width: '100%', textAlign: 'right' }}>
            <Tooltip title={selectedOrgData?.length && selectedOrgData[0]?.attributes?.name || 'Campaign Guru'}>
              <Typography variant="caption" noWrap component="div" sx={{ color: theme.palette.secondary.main }}>
                {selectedOrgData?.length && selectedOrgData[0]?.attributes?.name || 'Campaign Guru'}
              </Typography>
            </Tooltip>
          </div>
        </Typography>
        <Avatar
          color="secondary"
          variant="rounded"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            width: '34px',
            height: '34px',
            marginRight: 2,
            borderRadius: '8px',
            bgcolor: 'rgb(237, 231, 246)',
            cursor: 'pointer',
            '&:hover': {
              bgcolor: 'rgb(94, 53, 177)',
              '.MuiSvgIcon-root': {
                color: 'rgb(237, 231, 246)'
              }
            }
          }}
        >
          <MenuIcon fontSize='small' sx={{ color: theme.palette.secondary.main }} />
        </Avatar>

        <Avatar
          src={CgIcon}
          sx={{
            width: 30,
            height: 30,
            background: "#ddd",
            fontSize: "2rem",
            fontWeight: "400",
            textTransform: "capitalize",
            display: {
              xs: 'block',
              sm: 'none'
            }
          }}
        /> 
        <SearchContacts orgId={orgId} />
        <Typography
          // variant="caption"
          sx={{
            noWrap: true,
            alignItems: "right",
            alignContent: "right",
            textAlign: "right",
            paddingRight: "8px",
            flexGrow: 1,
            textTransform: 'capitalize'
          }}
        >
          Hi, {firstName ? getFullName(firstName, lastName) : userName}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "left",
            alignSelf: "right",
            textAlign: "left",
          }}
        >
          <Tooltip title={authUser?.email}>
            <IconButton
              onClick={handleMenuClick}
              aria-controls={openMenu ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
            >
              <Avatar sx={{ width: 32, height: 32 }}>
                {authUser?.username?.substring(0, 1).toUpperCase()}
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={openMenu}
          onClose={handleMenuClose}
          onClick={handleMenuClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              // zIndex: 10000,
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 10001,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {organisationsList?.data?.length > 0 && organisationsList?.data.map(org =>
            <MenuItem onClick={() => navigate(`/org/${org?.id}/contacts`)} style={{ backgroundColor: selectedOrgData?.length && selectedOrgData[0].id === org?.id ? '#0000000a' : '' }}>
              {/* <ListItemIcon>
                  <SettingsIcon fontSize="small" />
                </ListItemIcon> */}
              {org?.attributes?.name}
            </MenuItem>
          )
          }
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>

    <Drawer
      variant="temporary"
      open={mobileOpen}
      onTransitionEnd={handleDrawerTransitionEnd}
      onClose={handleDrawerClose}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
      }}
    >
      {drawer}
    </Drawer> 
    <CustomDrawer
      variant="permanent"
      sx={{
        display: { xs: 'none', sm: 'block' },
      }}
      open={open}
    >
      {drawer}
    </CustomDrawer>

    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto', marginTop: '56px' }}>
      <Paper component="main"
        className='cg-main-content-container'
        sx={{ flexGrow: 1, py: 2, backgroundColor: '#eef2f6', borderRadius: '8px' }}>
        {isOrgMember ? (
          children
        ) : (
          (selectedOrgData !== null && !isOrgMember) && <UnauthorizedAccessPage />
        )}
      </Paper>
    </Box>
  </Box>
    ;
}
export default DashboardLayout;
